.SessionPopularQuestionsContainer {
    padding: 10px;

    .SessionPopularQuestionsContainer-List {
        display: flex;
        flex-wrap: wrap;
    }

    .SessionPopularQuestionsContainer-Title {
        font-size: 14px;
        font-weight: 600;
        text-align:left;

        .SessionPopularQuestionsContainer-Collapse {
            color:rgb(32, 128, 218);
        }
    }
}
