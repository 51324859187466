.discussionQuestion {
    background-color: white;
    width: 100%;
    border-radius: 10px;
    font-family: "Roboto", arial;
    margin-top: 20px;
    .discussionContainer {
        padding: 7px 0;
        .resolvedDiscussionBadge {
            background-color: #70D59D;
            border-radius: 8px;
            padding: 3px 5px;
            width: 75px;
            margin-left: 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            .resolvedDiscussionText {
                color: white;
                font-size: 12px;
                font-weight: 400;
                margin: 0;
            }
            .resolvedCheckImage {
                margin-left: 3px;
            }
        }
        .discussionHeaderWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 16px;
            margin-right: 20px;
            padding-top: 5px;
            .upvoteAndUserInfoContainer {
                display: flex;
                .upvoteContainer {
                    .upvoteButton {
                        border: none;
                        background-color: transparent;
                        &:hover {
                            cursor: pointer;
                        }
                        .upvoteArrow {
                            width: 12px;
                            height: 12px;
                        }
                    }
                    .upvoteCount {
                        color: #7C8FBA;
                        font-size: 16px;
                    }
                }
                .userPhotoAndNameWrapper {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    .discussionProfileImage {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                    }
                    .discussionProfileUserName {
                        font-weight: 500px;
                        font-size: 14px;
                        margin-left: 8px;
                    }
                }
            }

            .discussionCommentButton {
                border: none;
                background-color: transparent;
                float: right;
                transform: translateY(0px);
                transition: transform 0.3s;
                &:hover {
                    cursor: pointer;
                    transform: translateY(-1px);
                }
            }
        }
        .lowerDiscussionContainer {
            display: flex;
            justify-content: space-between;
            margin-left: 50px;
            margin-right: 30px;
            .questionAndTagsWrapper {
                .tagsContainer {
                    margin: 12px 0px;
                }
            }
            .discussionQuestionTime {
                color: #6F6F6F;
                font-size: 13px;
            }
        }
        .discussionButtons{
            .discussionRemoveButtonWrapper {
                display: flex;
                justify-content: center;
                align-items: center;
                .discussionRemoveButton {
                    border: none;
                    background-color: transparent;
                    margin-top: 6px;
                    margin-bottom: 8px;
                    &:hover {
                        cursor: pointer;
                    }
                    .discussionRemoveIcon {
                        width: 14px;
                        height: 14px;
                    }
                }
            }

        }
        .discussionTAActionsWrapper {
            .discussionDoneButton {
                border: 1px solid #3594F1;
                border-radius: 4px;
                background-color: transparent;
                color:#3594F1;
                padding: 4px 30px;
                font-size: 14px;
                margin-top: 15px;
                margin-bottom: 10px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
        .discussionQuestionBody {
            font-weight: 300;
            font-size: 14px;
            color: #484848;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            .discussionQuestionContent {
                text-align: left;
            }
            &.isStudentUserDiscussion {
                .discussionQuestionContent {
                    margin-left: 10px;
                }
            }
            &.isTAUserDiscussion {
                display: block;
            }                
        }
        .discussionDivider {
            width: 95%;
            border: 1px solid #EBEBEB;
        }
    }
}

.discussionQuestion {

    .CommentBox {
        margin-top: 12px;
        padding-top: 18px;
        margin-bottom: 12px;
        padding-bottom: 18px;
        padding-left: 12px;
        margin-left: 11%;
        background-color: #f5f5f5;
        border: 1px solid #d8d8d8;
        width: 80%;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 300;
        text-align: left;

        .commentBody {
            margin-left: 30px;
            margin-right: 12px;
            margin-top: 3px;

            .commentEdit {
                margin-left: calc(100% - 30px);
                cursor: pointer;
                text-align: right;
                margin-right: 12px;
            }

            .commentText {
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.33;
                letter-spacing: normal;
            }

            .commentTextArea {
                width: 90%;
            }

            .commentBtnHolder {
                margin-top: 12px;
                font-weight: 300;

                .commentSaveBtn {
                    width: 107px;
                    height: 23px;
                    border: none;
                    color: white;
                    border-radius: 4px;
                    background-color: #3594f1;
                }

                .commentCancelBtn {
                    margin-left: 20px;
                    width: 107px;
                    height: 23px;
                    border-radius: 4px;
                    border: solid 1px black;
                    background-color: white;
                }
            }
        }
    }

    .userInformationImg {
        display: inline-block;
        margin-right: 6px;
        width: 30px;
        height: 30px;
        border-radius: 30px;
        vertical-align: middle;
        object-fit: cover;
    }

    .userInformationName {
        font-weight: 500;
        text-align: left;
        line-height: 12px;
        margin: 0em;
        color: #595959;
        vertical-align: middle;
        letter-spacing: 0.1px;

        .assigned {
            font-weight: normal;
            color: #9e9e9e;
        }
    }


}