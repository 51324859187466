$red: #f67d7d;

.CalendarDaySelect {
    background: white;
    color: $text-grey;
    border-bottom: solid 1px #e3e3e3;

    .month {
        font-family: Roboto;
        font-size: 17px;
        font-weight: 500;
        margin: 0 20px;
        padding: 10px 0;
        border-bottom: solid 1px #ebebeb;
    }

    .selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;

        .LastWeek,
        .NextWeek {
            cursor: pointer;
            background-color: transparent;
            border-color: transparent;
        }

        .LastWeek,
        .NextWeek:focus {
            outline: 0;
        }
    }
}

.menuDate .date:hover {
    background-color: #fcd9d9;
    border-radius: 50%;
    margin: auto;
    margin-left: -2px;
    height: 30px;
    width: 30px;
}

.menuDate:focus {
    outline: 0;
}

.menuDate {
    cursor: pointer;
    height: 64px;
    width: 30px;
    background-color: transparent;
    border-color: transparent;
    padding: 0;

    &.active {
        cursor: default;

        &>.date {
            color: white;
            background-color: $red;
            border-radius: 50%;
            margin: auto;
            margin-left: -2px;
            height: 30px;
            width: 30px;
        }
    }

    &>.day {
        font-size: 11px;
        padding-bottom: 5px;
        color: #777;
    }

    &>.date {
        font-size: 15px;
        padding: 6px 0px;
    }

    &>.indicator {
        height: 15px;
    }

    &>.notification {
        font-size: 32px;
        height: 20px;
        color: $red;
    }
}
