.ProfessorAddNew {
    .Add.false {
        display: none;
    }

    .ExpandedAdd.false {
        display: none;
    }

    .Add {
        .NewOHButton {
            color: $text-grey;
            background-color: white;
            padding: 15px;
            border-radius: 3px;
            font-size: 14px;
            box-shadow: 2px 2px 4px 0 rgba(158, 158, 158, 0.5);
            margin-top: 30px;
            margin-right: 30px;
            float: left;

            .plus.icon {
                margin-right: 10px;
                color: black;
            }
        }
    }

    .ExpandedAdd {
        margin-top: 30px;
        background-color: white;
        width: 100%;
        text-align: left;
        box-shadow: 2px 2px 4px 0 rgba(158, 158, 158, 0.5);
        padding-bottom: 80px;

        .NewOHHeader {
            border-bottom: 1px solid #d9d9d9;

            .ExpandedNewOHButton {
                background-color: white;
                padding: 15px;
                border-radius: 3px;
                text-align: left;
                color: $text-grey;

                .plus.icon {
                    margin-right: 10px;
                    color: black;
                }
            }
        }
    }
}
