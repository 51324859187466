.wrappedBackground {
  background-color: rgba(112, 112, 112, 0.8);;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;  

  I {
    position: absolute;
    top: 12px;
    right: 10px;
  }
}

.wrappedContainer {
  background: linear-gradient(90deg, #80B0DE 3.12%, #93A7E4 97.75%);
  border-radius: 8px;
  padding: 2rem;
  // position: fixed;
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: auto;
  width: 825px;
  height: 475px;
  color: #FFFFFF;
  align-items: center;
  justify-content: center;
  overflow: hidden;  
  box-shadow: 0px 4px 4px 0px #00000040;


}

.closeButtonContainer {
  margin: 0;
  padding: 0;
  position: "absolute";
  bottom: "2rem";
  right: "1.8rem";
}



.navigateStageVisible {
  position: absolute;
  top: 50%; 
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  transition: 0.5s;
  border-radius: 100%;
  border-radius: 100%;
  &:hover {
    background-color: rgba(0,0,0, 0.05);
  }
  .MuiSvgIcon-root {
      font-size: 2rem; 
  }
}

.prev {
  left: 0;
  justify-content: flex-start; 
  padding-left: 10px; 
  .MuiSvgIcon-root {
    margin-left: 6px;
  }
  .MuiSvgIcon-root {
    margin-left: 6px;
  }
}

.next {
  right: 0;
  justify-content: flex-end; 
  padding-right: 10px;
}

.dotsContainer, .navigateStage {
  display: none;
}

.dotsContainerVisible {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 15px; // Adjust as needed for your modal
  left: 0;
  right: 0;
  margin: auto;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #bbb;
  margin: 0 5px;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgb(255, 255, 255);
}
}

.dot.active {
  background-color: #FFFF; 
}

.welcomeSlide{
  display: flex;
  flex-direction: column;
  width: 423px;
  justify-content: space-between;
}

.visit{
  display: flex;
  flex-direction: column;
  width: 750px;
  justify-content: space-between;

  &.top-text{
    position: absolute;
    top: 3rem;
    left: -3.3rem;
    font-size: 35px;
    font-weight: 600;
    color: #080680;
  }

  &.mid-text{
    position:absolute;
    top: 7.7rem;
    left: -20.2rem;
    font-size: 27px;
  }

  &.num-visits{
    position: absolute;
    top: 260px;
    left: 140px;
    font-size: 297px;
    width: 336px;
    color: #FFA3A3;
    opacity: 0.88;
  }

  &.couple{
    width: 166px; 
    position: absolute; 
    right: 9rem;
    bottom: 12rem;
  }

  &.girl{
    width: 68px;
    position: absolute;
    right: 7rem;
    bottom: 9rem;
  }

  &.bottom-text{
    position: absolute;
    left: 527px;
    top: 320px;
    font-weight: bold;
    text-align: left;
  }
}

.timeSpent{
  &.top-text{
    position: absolute;
    top: 3rem;
    left: 3rem;
    font-weight: 500;
    font-size: 27px;
  }

  &.nums-container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.five-nums{
    position: absolute;
    top: 50%;
    left: 52.5%;
    transform: translate(-50%, -50%);
  }

  &.minutes-text{
    position: absolute;
    bottom: 7.5rem;
    right: 3rem;
    font-weight: 500;
    font-size: 27px;
    text-align: right;
    font-weight: bold;
    width: 300px;
  }

  &.bottom-text{
    position: absolute;
    bottom: 5rem;
    right: 3rem;
    font-weight: 500;
    font-size: 27px;
    text-align: right;
    font-weight: bold;
    width: 300px;
  }
  &.smallGirl{
    position: absolute;
    width: 39px;
    height: 59px;
    bottom: 6.35rem;
    left: 6rem;
  }
}

.personality{
  display: flex;
  align-items: center; 
  justify-content: space-evenly;
  &.container{
    display: flex;
    flex-direction: column;
    width: 45%;
    align-items: baseline;
  }
  &.text{
    font-weight: 500;
    font-size: 27px;
    margin-bottom: 2rem;
    line-height: 2.5rem;
    text-align: left;
    font-size: 35px;
  }
  &.personalityType {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 35px;
    color: #080680;
    text-transform: uppercase;
  }
  &.personalityIcon {
    width: 242px;
  }
}

.favTA{
  &.all{
    display: flex;
    position: absolute;
    top: 7rem;
    align-items: center;
    justify-content: space-evenly;
  }
  &.container{
    display: flex;
    flex-direction: column;
    width: 337px;
    height: 100%;
    padding-left: 5%;
  }

  &.top-text{
    font-weight: 500;
    font-size: 27px;
    margin-bottom: 2rem;
    line-height: 2.5rem;
    text-align: left;
  
  }
  &.taName {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 35px;
    color: #080680;
    text-transform: uppercase;
    text-align: left;
  }
  &.ta-img{
    width: 323px;
  }
}

.timeHelped{
  &.all{
    display: flex;
    flex-direction: column;
    width: 750px;
    justify-content: space-between;
  }
  &.format{
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
  }

  &.top-text{
    position: absolute;
    top: 3.5rem;
    left: 2.7rem;
    font-size: 35px;
    font-weight: 600;
    color: #080680;
  }

  &.mid-text{
    position: absolute;
    top: 9rem;
    left:3rem;
    font-weight: 500;
    font-size: 27px;
  }
  &.num{
    position: absolute;
    top: 52%;
    left: 48.5%;
    transform: translate(-50%, -50%);
    font-size: 196.57px;
    line-height: 230.36px;
    color: #F1A4AB;
  }

  &.group{
    width: 305px;
    height: 239.6px;
    position: absolute;
    top: 17rem;
    left: 2rem;
  }

  &.bottom-text{
    position: absolute;
    top: 26rem;
    right: 2rem;
    font-weight: 500;
    font-size: 27px;
    width: 414px;
    text-align: right;
  }
}

.taStudentsHelped{
  &.top-text{
    position: absolute;
    top: 3.5rem;
    left: 3.5rem;
    font-weight: 500;
    font-size: 27px;
  }
  &.num{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 225.97px;
    font-weight: 600;
    color: #080680;
  }
  &.students{
    position: absolute;
    bottom: 7.5rem;
    right: 5rem;
    font-weight: 500;
    font-size: 27px;
    text-align: right;
    width: 300px;
  }
}

@keyframes slideInTopRight {
  0% {
      transform: translate(-100%, -100%) rotate(18.6deg);
      right: -50%;
      opacity: 0;
  }
  100% {
      transform: translate(0%, 0%) rotate(18.6deg);
      right: -3%;
      opacity: 1;
  }
}

@keyframes slideInBottomLeft {
  0% {
      transform: translate(-100%, -100%) rotate(8.63deg);
      left: -50%;
      opacity: 0;
  }
  100% {
      transform: translate(0%, 0%) rotate(8.63deg);
      left: -1%;
      opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0%
  }

  100% {
    opacity: 100%;
  }
}

.intro-title{
  animation: fadeIn 1s ease-in both;
}

.intro-title:nth-child(2) {
  animation-delay: 0.75s;
  animation-duration: 0.5s;
}

.banner {
  position: absolute;
  background-color: #D2D0FE; 
  border: solid 0.3px;
  color: #000000;
  font-weight: 500;
  padding: 10px 20px;
  font-size: 15px;
  white-space: nowrap;
  opacity: 0;
  overflow: hidden;

  &.top-right {
      top: 9rem;
      right: -50%; // Start from outside the right edge
      transform: rotate(18.6deg);
      transform-origin: top right;
      animation: slideInTopRight 1s forwards; 
  }

  &.bottom-left {
      bottom: 4rem;
      left: -50%; // Start from outside the left edge
      transform: rotate(8.63deg);
      transform-origin: bottom left;
      animation: slideInBottomLeft 1s forwards;
  }

  &.bottom-time{
      bottom: 4.3rem;
      left: -1.5%;
      opacity: 1;
      transform: rotate(6.08deg);
      transform-origin: bottom left;
  }

  &.bottom-favta{
    bottom: 4.3rem;
    left: -1.5%;
    opacity: 1;
    transform: rotate(4.47deg);
    transform-origin: bottom left;
  }

  &.bottom-ta-helped{
    opacity: 1;
    bottom: 0.25rem;
    right: -0.25rem;
    border: 0.3px 0px 0px 0px;
    transform: rotate(-5.62deg);
  }

  &.bottom-conclusion{
    bottom: 4.3rem;
    left: -1.5%;
    opacity: 1;
    transform: rotate(16.45deg);
    transform-origin: bottom left;
  }
}

.banner.visible {
  display: block;
  animation: slideIn 1s ease forwards; /* 1 second duration */
}

.conclusionText {
  &.top-text{
    color: #080680; 
    font-weight: 600;
    position: absolute;
    width: 475px;
    height: 38px;
    top: 42px;
    left: 40px;
    font-size: 35px;
  }
  &.center-text {
    font-weight: 500;
    width: 279px;
    height: 71px;
    position: absolute;
    top: 195px;
    left: 270px;
    line-height: 40px;
    font-size: 35px;
  }
}

@keyframes slideOut {
  0%{
    right: 1rem;
    opacity: 1;
  }
  100%{
    right: -50%;
  }
}

.bus{
  width: 280px;
  height: 153px;
  position: absolute;
  top: 326.61px;
  right: 1rem;
  transform: rotate(0.94deg);
  animation: slideOut 5s forwards;
}

.head{
  width: 15.35px;
  height: 23.21px;
  position: absolute;
  bottom: 12.34rem;
  left: 6.5rem;
  transform: rotate(4.55deg);
}

.body{
  width: 91px;
  height: 108.9px;
  position: absolute;
  bottom: 4.9rem;
  left: 1.5rem;
}

@keyframes wave {
  0%{
    transform: rotate(9.66deg);
  }
  100%{
    bottom: 10.75rem;
    transform: rotate(-90deg);
  }
}

.arm{
  width: 42.21px;
  height: 33.71px;
  position: absolute;
  bottom: 10rem;
  left: 5.79rem;
  transform: rotate(9.66deg);
  animation: wave 3s forwards;
}

.smallGirl{
  position: absolute;
  width: 39px;
  height: 59px;
  bottom: 1.7rem;
  left: 8rem;
}