.FeedbackPopup.feedback {
  flex-direction: column;
}

.QMeLogo {
  display: block;
  width: 83px;
  padding-bottom: 40px;
  margin-top: 70px;
}

.topPanel {
  height: 45vh;
  background-image: linear-gradient(#155693 0%, #6db9ea 100%);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  line-height: 1.4;
  font-size: 18px;
  margin-bottom: 40px;
  position: relative;

  .feedbackText {
      background-color: transparent;
      color: white;
      border: none;
      text-decoration: underline;
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      position: absolute;
      bottom: 30px;
  }

  &.feedback {
      background-image: none;
      background-color: white;
      color: $number-blue;
      justify-content: center;
      font-size: 19px;
      margin-bottom: 0;
      padding: 0 40px;
      position: relative;

      h2 {
          font-size: 22px;
          padding: 12% 0 30px;
      }

      .closeIcon {
          position: absolute;
          background-color: transparent;
          border: none;
          color: black;
          top: 5px;
          right: 5px;
          cursor: pointer;
      }

      .QMeLogo {
          float: left;
          padding-bottom: 0;
          margin-top: 0;
      }
  }
}

.bottomPanel {
  height: 55vh;

  &.feedback {
      background-color: $brand-primary;
      padding-top: 80px;

      .feedbackButton {
          background-color: $button-blue;
          box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.5);
          border-radius: 4px;
          padding: 15px 50px;
          width: 247px;
          font-size: 20px;
          height: 40px;
          color: white;
      }
  }

}

.hintText {
  color: grey;
}

@media only screen and (min-width: $mobile-breakpoint) { // Desktop View
  .topPanel,
  .bottomPanel {
      height: 500px;
      font-size: 20px;
      box-shadow: 10px 10px 111px 0px rgba(0, 0, 0, 0.65);
      min-width: 400px;
      max-width: 475px;
  }

  .topPanel {
      margin: 0;
      border-radius: 15px 0 0 15px;

      &.feedback {
          height: 300px;
          min-width: 800px;
          border-radius: 15px 15px 0 0;
          display: block;

          .QMeLogo {
              margin: 70px 30px;
          }
      }
  }

  .bottomPanel {
      color: #8d8d8d;
      border-radius: 0 15px 15px 0;
      background-color: white;
      position: relative;

      &.feedback {
          height: 200px;
          min-width: 800px;
          border-radius: 0 0 15px 15px;
      }
  }

  .QMeLogo {
      width: 118px;
      margin-top: 100px;
  }

  .hintText {
      padding-top: 50%;
  }

}


