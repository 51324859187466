.ProfessorOHInfoDelete {
    .question {
        padding-top: 28px;
    }

    .info {
        background-color: #edeff2;
        margin: 15px 30px;
        padding: 15px;

        .ta {
            font-weight: bold;
            padding-bottom: 15px;
        }

        span {
            padding: 2px 20px;
            display: inline-block;
        }
    }
}