.ProfessorView{
    //Datepicker CSS
  .Category-dropdown-container{
    margin-top: 15px;
  }

  .category-container {
    margin-top: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    padding-top: 13px;
    text-align: right;

    .categoryName {
      font-family: Roboto;
      font-size: 21.8px;
      font-weight: normal;
      color: #676767;
      text-align: left;
      margin-left: 12px;
      margin-bottom: 0;
    }

    .stats-graph-container {
      display: flex;
      align-items: center;
    }

    .category-stats-container {
      display: inline-block;
      text-align: center;
      width: 12%;
      margin-left: 20px;
      color: #676767;

      .totalQuestions {
        margin-bottom: 0;
        font-size: 32px;
        line-height: 30px;
      }

      .totalQuestionsLabel {
        margin-bottom: 28px;
        font-size: 12px;
      }

      .percentResolved {
        margin-top: 30px;
        margin-bottom: 0;
        font-size: 32px;
        line-height: 30px;
      }

      .percentResolvedLabel {
        font-size: 12px;
      }
    }

    .TagsBarChart {
      margin-top: 25px;
      margin-right: 50px;
      width: 82%;
      display: inline-block;
    }
  }

  // Dropdown CSS
  .ui.selection.dropdown {
    width: 200px;
    background-color: #fff;
    border-radius: 0.285714rem;
    border: 1px solid rgba(34,36,38,.15);
    color: rgba(0,0,0,.87);
    padding: .78571429em 2.1em .78571429em 1em;
  }

  .ui.selection.dropdown>.dropdown.icon{
      padding: .91666667em;
  }
}