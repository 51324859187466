$header-color: #7ab7fe;
$even-color: #edeff2;
$odd-color: white;

.ProfessorCalendarTable {
    text-align: left;
    color: #4a4a4a;

    tbody:first-child{
        tr:first-child {
            th:first-child {
                border-radius: 10px 10px 0px 0px;
            }
        }
    }

    .ui.loader {
        position: relative;
        margin-top: 100px;
    }

    .Calendar {
        width: 100%;
        border-collapse: collapse;

        .rows {
            width: 100%;
        }

        .NoOH {
            text-align: center;
        }

        th {
            padding: 14px 0px;
            color: white;
            font-size: 20px;
            padding-left: 5%;
            background-color: $header-color;
            font-weight: 500;
        }

        td {
            padding: 7px 0px;
        }

        td:nth-child(1) {
            padding-right: 15px;
        }

        td:nth-child(2) {
            width: 35%;
            padding-right: 15px;
        }

        td:nth-child(4), td:nth-child(5) {
            width: 1%;
            padding: 0 10px;
        }
    }
}
