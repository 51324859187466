.commentsContainer {
    position: relative;
    width: 100%;
    display: inline-block;
    .commentsLine {
        position: absolute;
        border: 3px solid #B0B0B0;
        border-radius: 3px;
        height: 100%;
        width: 0px;
        cursor: pointer;
        transition: border 0.2s ease-in-out;
        &:hover {
            border: 3px solid #474747;
        }
    }
}