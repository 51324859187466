.logoutMenu {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 101;
  padding-top: 42px;
  user-select: none;
  cursor: pointer;
  outline: none;

  li {
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: white;
    width: 100%;
    height: 52px;
    font-size: 15px;
    font-weight: 300;
    border-bottom: solid 0.5px #e3e3e3;
    display: block;

    a {
      color: rgba(0, 0, 0, 0.87);
      width: 100%;
      padding: 16px;
      display: inline-block;
      margin: auto;
    }
  }
}

.logoutMenu li {
  padding-left: 80px;
}
