div.warningArea {
    padding: 0.3in;
    display: inline-block;
}

aside.CalendarView {
    position: fixed;
    top: 50px;
    bottom: 0;
    left: 0;
    width: 390px;
    box-shadow: 1px 0 5px 0 rgba(0, 0, 0, 0.25);
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    user-select: none;
}

section.StudentSessionView {
    position: fixed;
    top: 50px;
    bottom: 0;
    right: 0;
    left: 390px;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}

section.StudentSessionView::-webkit-scrollbar,
aside.CalendarView::-webkit-scrollbar {
    display: none;
}

.splitQuestions {
    margin: 0 48px;
}

section .SessionJoinButton {
    width: 217px;
    display: block;
}

.noSessionSelected {
    color: $text-grey;
    font-family: Roboto;
    font-size: 18px;
    padding-top: 25vh;
}

.welcomeMessage {
    padding-top: 100px;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 300;
    color: $text-grey;
}

.welcomeName {
    font-weight: 500;
}

.undoContainer {
    width: 100%;
    background-color: #b0b0b0;
    color: #ffffff;
    height: 56px;
    text-align: left;
    top: 0;
    z-index: 1;
    position: sticky;
    position: -webkit-sticky;

    .undoClose {
        position: absolute;
        top: 2px;
        left: 4px;
        cursor: pointer;
    }

    .undoText {
        line-height: 56px;
        text-align: center;

        .undoLink {
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.Bookmark {
    position: absolute;
    border: 10px solid $tag-blue;
    border-bottom: 12px solid transparent;
    height: 33px;
    top: 0;
}

@media only screen and (max-width: $mobile-breakpoint) {
    aside.CalendarView,
    section.StudentSessionView {
        width: 100%;
        position: relative;
        height: 100%;
        left: 0;
        top: 0;
    }

    .splitQuestions {
        margin: 10px;
    }

    .modal {
        border-radius: 0;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: none;
        height: 100%;
        width: 100%;
    }

    section .SessionJoinButton {
        margin: 15px auto 0;
        width: 90%;
        max-width: 400px;
    }

    .undoContainer {
        background-color: #79b8b8;
    }
}

.feedbackModal {
    .Feedback {
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        @media only screen and (min-width: $mobile-breakpoint) {
            top: 15%;
            left: 15%;
        }
    }
}

.feedbackShade {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    background-color: hsla(0, 0%, 44%, 0.8);
}
