.leave-queue-background {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Roboto";
    font-weight: 400;
    background-color: rgba(112, 112, 112,0.8);
    animation: fade 0.6s;
}

.leave-queue-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    background-color: white;
    border-radius: 8px;
    height: 180px;
    width: 480px;
    animation: moveUp 0.6s;

    .leave-queue-button {
        position: absolute;
        top: 10px;
        right: 10px;
        border: none;
        background-color: transparent;
        padding: 0;
        cursor: pointer;
        width: 15px;
        height: 15px;

        img {
            width: 15px;
            height: 15px;
            margin: 0;
            padding: 0;
        }
    }

    img {
        width: 50px;
        height: 50px;
        margin: 10px;
    }

    h2 {
        color: #040202;
        font-weight: 400;
        font-size: 18px;
    }

    .leave-queue-option {
        color: white;
        width: 50%;
        position: absolute;
        bottom: 0;
        border: none;
        font-size: 18px;
        padding: 18px 0;
        cursor: pointer;
        transition: background-color 0.3s;
    }

    .leave-queue-body {
        display: "flex";
        flex-direction: "column";
    }
}

.leave-queue-prompt {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

@media screen and (max-width: 850px) {
    .leave-queue-container {
        height: 145px;
        width: 380px;
        img {
            margin-top: 50px;
            height: 30px;
            width: 30px;
        }
        h2 {
            font-size: 12px;
        }
        .leave-queue-option {
            font-size: 12px;
        }
        .cancel-button {
            font-weight: "normal";
            border-color: "#3594F1"; 
            color: "#6597D6"; 
            margin-left: "0.5rem";
            margin-right: "12.5rem";
        }
        .remove-button {
            font-weight: "normal";
            background-color: "#A42921";
        }
    }
}

@keyframes fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(10px)
    }
    100% {
        transform: translateY(0px)
    }
}