$text-color: #888888;
$num-color: #545454;
$time-color: #696969;
$border-color: #bfbfbf;
$interior-color: #e6e6e6;
$live: #70d59d;
$open: #ffdba6;

.noHoursHeading {
    padding-top: 20vh;
    font-weight: bold;
    color: $text-grey;
}

.noHoursBody {
    font-family: Roboto;
    font-size: 15px;
    color: $text-grey;
}

.DateWrapper {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    color: black;
    padding-top: 11px;
    padding-left: 10px;
    line-height: 0;
    margin: 0;
    text-align: left;

    .CalendarExportIcon {
        margin-left: 7px;
        margin-bottom: 18px;
    }
}

.CalendarSessions {
    .pastHeader {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .icon {
            cursor: pointer;
        }
    }
}

.CalendarSessions h6 {
    font-size: 14px;
    font-weight: normal;
    color: $text-grey;
    text-align: left;
    padding-left: 12px;
    margin: 0;
    padding-top: 12px;
    line-height: 0;
}

@media only screen and (min-width: $mobile-breakpoint) {
    .CalendarSessionCard.active {
        background-color: #d8d8d8;
    }
}

.CalendarSessionCard {
    font-size: 11px;
    padding-top: 20px;
    margin: 20px 0px;
    overflow: hidden;
    display: flex;
    cursor: pointer;
    color: $text-color;
    background-color: white;
    position: relative;

    > * {
        margin: 0px 10px;
    }

    .Bookmark {
        right: 35px;
    }

    .TimeInfo {
        display: inline-block;
        color: $time-color;
        width: 50px;
    }

    .Wrapper {
        display: flex;
    }

    .Type {
        background-color: #f46565;
        border-radius: 8px;
        width: 70px;
        height: 20px;
        margin-bottom: 10px;
        margin-top: 7px;
        color: white;
    }

    .Type.Office {
        background-color: #5599db;
        width: 27px;
    }

    .Indicator {
        margin: 9px 0 0 8px;

        .Circle {
            border: solid 1.5px white;
            border-radius: 14px;
            height: 14px;
            width: 14px;
        }

        &.Ongoing > * {
            background-color: $live;
            box-shadow: 0 1px 4px 1px $live;
        }

        &.Open > * {
            background-color: $open;
            box-shadow: 0 1px 4px 1px $open;
        }

        &.Past,
        &.Upcoming {
            .Circle {
                background-color: $interior-color;
                border-color: $border-color;
                box-shadow: none;
            }
        }
    }

    .CalendarCard {
        flex-grow: 1;
        border-radius: 5px;
        padding-bottom: 15px;
        text-align: left;

        .Location {
            color: $text-grey;
            font-size: 14px;
            font-weight: 500;
        }

        .CalendarExportIcon {
            margin-left: 7px;
        }

        .Tas {
            color: #207981;
        }

        .Queue {
            .Ahead {
                .AheadNum {
                    margin-right: 8px;
                    color: #FF5A60;
                }
            }
            .Finished {
                .FinishedNum {
                    color: #14A856;
                }
            }
        }

        .TimeDesc {
            color: #7ab7fe;
        }
    }

    .OpenButton {
        color: $text-grey;
        font-size: 30px;
        margin-top: 15px;
        padding-bottom: 10px;
        padding-right: 10px;
    }
}
