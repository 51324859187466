.CourseCard {
    margin: 25px 15px;
    border-radius: 4px;
    box-shadow: 5px 10px 20px gainsboro;
    background-color: white;
    text-align: left;
    flex: 1 1 400px;
    width: 300px;

    &.selected {
        opacity: 0.6;
    }
    
    &.active:hover {
        cursor: pointer;
        box-shadow: 5px 10px 20px gray;
    }

    .courseText {
        padding: 30px;
        min-height: 150px;

        .courseCode {
            display: inline-flex;
            align-items: center;
            font-size: 25px;
            font-weight: bold;
            margin-bottom: 30px;

            .role {
                margin-left: 10px;
                padding: 2px 10px;
                border-radius: 4px;
                border: solid 1px black;
                font-size: 18px;
            }
        }

        .courseName {
            font-size: 20px;
        }
    }

    .courseColor {
        position: relative;
        bottom: 0;
        height: 50px;
        background-color: #ffc5a1;

        .icon, div {
            font-size: 20px;
            text-align: center;
            display: block;
            margin: auto;
            padding-top: 15px;
        }
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .CourseCard {
        // min-width: 200px;
        // max-width: 200px;
    }
}
