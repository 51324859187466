.EditButtons:focus {
    outline: none;
}

.ProfessorOHInfo {
    margin-left: 40px;

    .icon {
        margin-right: 15px;
    }

    .plus {
        margin-right: 5px;
    }

    .shift {
        margin-left: 32px;
    }

    .TAZoomCheckbox {
        margin-left: 32px;
    }

    .long {
        width: 40%;
        min-width: 200px;
    }

    .ui.buttons.ModalitySelector {
        margin: 10px;
    }

    .TA {
        .AddTA.First {
            i.user.icon {
                visibility: visible;
            }

            .AddTAButton {
                color: #7ab7fe;
                background-color: white;
                font-size: 14px;
            }

            .AddTAButton.true {
                color:gainsboro;
            }
        }

        .AddTA.Additional {
            margin-top: 14px;
            i.user.icon {
                visibility: hidden;
            }
            .AddTAButton {
                font-size: 14px;
            }
        }
    }

    .row, .Time>.datePicker {
        margin-top: 24px;
    }

    .Time {
        .datePicker {
            display: inline-block;
        }

        // CSS to show time select only
        .timePicker {
            .react-datepicker__month-container {
                display:none;
            }
            .react-datepicker {
                .react-datepicker__triangle {
                    left: 35px;
                }
                .react-datepicker__time-container {
                    border-left: 0;
                }
                .react-datepicker__time {
                    border-radius: 0.3rem;
                }
                .react-datepicker__time-box {
                    border-radius: 0.3rem;
                }
            }
            .react-datepicker__navigation {
                display: none;
            }
        }
    }
}

.ExpandedEdit, .ExpandedAdd {
    .Bottom {
        font-size: 14px;
        padding: 10px 0px;
        border-radius: 3px;
        font-size: 14px;
        margin: 20px 15px 13px;
        width: 125px;
    }

    .EditButtons {
        border-top: 1px solid #d9d9d9;
        margin-top: 20px;
        background-color: white;
        width: 100%;

        .EditNotification {
            margin-top: 30px;
            margin-right: 20px;
            float: right;
            color: red;
        }

        .Cancel {
            float: right;
            background-color: white;
            border: 1px solid gray;
        }

        .Edit {
            float: right;
            background-color: #7ab7fe;
            border: 1px solid #7ab7fe;
            color: white;
        }

        .Edit:disabled {
            background-color: gainsboro;
            border: 1px solid gainsboro;
        }
    }
}
