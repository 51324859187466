.DetailedQuestionView {
    background-color: rgba(0,0,0,0.3);
    bottom: 0;
    left: 0;
    padding: 20px;
    position: fixed;
    right: 0;
    text-align: left;
    top: 0;
    width: 100%;
    z-index: 1;

    &.PopupInvisible {
        display: none;
    }

    &.PopupVisible {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .DetailedCloseButton, .DetailedResolveButton {
        background-color: white;
        border: solid 1px black;
        margin-top: 10px;
        padding: 10px;
        font-size: 16px;
        border-radius: 10px;
        width: 100%;
    }

    .DetailedCloseButton {
        color: red;
        cursor: pointer;
    }

    .DetailedQuestionInfo {
        border: solid 1px black;
        border-radius: 10px;
        background-color: white;
        padding: 10px;
        overflow-y: auto;
        top: 50%;
    }

    .StudentInfo {
        padding-bottom: 20px;

        .DetailedTags {
            p {
                display: inline;
                font-size: 11px;
                color: white;
                border: 1px  solid #999999;
                background-color: #999999;
                padding: 1px 7px;
                margin: 3px;
                border-radius: 10px;
            }
        }
    }


    header {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 0px;
    }

    ul {
        text-align: left;
        list-style-type: none;
        margin: 0;
        padding: 0;
        color: #747474;

        li {
            padding: 5px 0px;
            border-bottom: solid 1px #747474;

            &:last-child {
                border-bottom: none;
            }
        }
    }
}
