// Workaround so dropdown is visible in table cell
.dropdownCell {
    overflow: visible !important;
}

.rolesTable {
    max-width: 800px;
    margin-top: 40px !important;

    th {
        font-weight: 500;
    }

    .import-buttons {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        button {
            margin: .25em;
        }
    }
}
