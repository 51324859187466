.SessionPopularQuestion {
    background-color: #f6f6f6;
    border-radius: 3px;
    box-shadow: 2px 2px 4px 0 rgba(158, 158, 158, 0.5);
    height: 75px;
    margin: 10px 1.667%;
    max-width: 200px;
    padding: 1%;
    position: relative;
    text-align: left;
    width: 28%;

    .SessionPopularQuestion-People {
        bottom: 0;
        color: #000000;
        font-size: 14px;
        padding: 2px;
        position: absolute;
    }

    .SessionPopularQuestion-Text {
        color: $text-grey;
        font-size: 11px;
        height: auto;
        padding: 2px;
        width: 100%;
    }

    //.SessionPopularQuestion-PeopleLogo {
    //    width: 14px;
    //    height: 14px;
    //    vertical-align: middle;
    //}
}
