.ProfessorDelete.true {
    display: block;
}

.ProfessorDelete {
    display: none;
    position: fixed;
    z-index: 1;
    left: 200px;
    top: 0;
    width: calc(100% - 200px);
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    text-align: justify;

    .content {
        text-align: center;
        background-color: #fefefe;
        position: relative;
        margin-top: 15%;
        max-width: 100%;
        display: inline-block;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        -webkit-font-smoothing: subpixel-antialiased;
        -webkit-filter: blur(0);

        .x {
            padding: 4px;
            float: right;
        }

        .Action, .Cancel {
            font-size: 14px;
            padding: 10px 40px;
            border-radius: 3px;
            margin: 20px 15px;
            background-color: white;
            border: 1px solid gray;
        }

        .Action {
            background-color: #7ab7fe;
            border: 1px solid #7ab7fe;
            color: white;
        }

        .Action:disabled {
            background-color: gainsboro;
            border: 1px solid gainsboro;
        }

        .EndedText {
            color: red;
            padding-top: 15px;
        }
    }
}
