.SessionNotification {
  height: 50px;
  background: #f2f9ff;
  border: 1px solid #5599db;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
  display: flex;
  flex-direction: row;
  text-align: left;
  position: fixed;
  margin: auto;
  width: 60%;
  top: 650px;
  bottom: 0;
  z-index: 9999;

  .label {
    align-self: center;
    justify-self: flex-start;
    color: #484848;
    font-family: Roboto;
    font-style: normal;
    font-weight: 250px;
    font-size: 14px;
    letter-spacing: 0.642857px;
    padding: 40.59px;
  }
  .button {
    align-self: center;
    margin-left: auto;
    outline: none;
    border: none;
    background-color: transparent;
    color: #5599db;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500px;
    line-height: 48px;
    padding: 24px;
    cursor: pointer;
  }

  img {
    align-self: center;
    position: absolute;
    size: 80%;
    left: 14px;
  }

  p {
    width: 70%;
    float: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.642857px;
    margin: 0;
    color: #484848;
  }
}

@media only screen and (max-width: $mobile-breakpoint) {
  .SessionNotification {
    p {
      width: 60%;
      font-size: 16px;
    }
  }
}
