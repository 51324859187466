.ProfessorTagsTable {
    .Tags {
        .Pair {

            .ChildTagSeparator {
                margin: 0 10px;
                color: #8f8f90;
                font-size: 10px;
                vertical-align: text-bottom;
            }

            .AssignmentTag {
                font-weight: 500;
                color: $text-grey;
            }

            &.true {
                .ChildTagSeparator {
                    color: #ffffff;
                }
            }
        }
    }
}
