.newCommentContainer {
    width: 98%;
    background-color: #D6EAFE;
    border-radius: 10px;
    display: flex column;
    align-items: flex-start;
    float: right;
    margin-bottom: 15px;
    .newCommentWrapper {
        margin-left: 30px;
        margin-top: 15px;
        .newCommentHeader {
            display: flex;
            align-items: center;        
            .newCommenterProfile {
                width: 25px;
                height: 25px;
                border-radius: 50%;
            }
            .newCommenterName {
                font-family: Roboto;
                color: #545454;
                font-size: 14px;
                font-weight: 400;
                margin: 0px 0px 0px 5px;
            }
        }
        .newCommentInput {
            border: none;
            border-radius: 4px;
            background: #FFFFFF;
            font-size: 14px;
            padding: 10px 10px;
            font-weight: 300;
            width: 97%;
            display: block;
            margin-top: 10px;
        }
        .addNewCommentButton {
            color: #5599DB;
            text-decoration: underline;
            font-size: 12px;
            float: right;
            background-color: transparent;
            border: none;
            cursor: pointer;
            margin: 10px 3% 15px 0;
        }
    }
    

}