.SessionJoinButton {
    display: inline-block;
    background: #80bfff;
    width: 90%;
    height: 45px;
    margin: 24px 0px;
    border: solid #80bfff;
    border-radius: 3px;
    box-shadow: 0px 2px 3px #aaaaaa;
    user-select: none;
    cursor: pointer;

    &.disabled {
        background: #d2d2d2;
        border: none;
        box-shadow: none;
        cursor: default;
    }

    p {
        margin: auto;
        line-height: 39px;
        color: white;
        font-size: 15px;
        vertical-align: middle;
    }
}
