$deselect: #898989;
$selectprimary: #5f87ef;
$selectsecondary: #e4efff;

.ProfessorSidebar {
    height: 100%;
    width: 200px;
    position: fixed;
    z-index: 1;
    top: 50px;
    background-color: white;
    overflow-x: hidden;
    box-shadow: 2px 0 8px 1px rgba(190, 190, 190, 0.5);
    font-weight: 300;
    user-select: none;

    .header {
        background: #7ab7fe;
        background-image: linear-gradient(74deg, #668ae9, #6db9ea);
        color: white;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 25px;
        height: 46px;

        .dropdown {
            font-size: 15px;
            padding-left: 10px;
        }
    }

    .manage,
    .actions {
        font-size: 15px;
        display: inline-block;

        i.icon {
            padding: 0 30px;
        }

        .viewIconContainer {
            padding: 0 30px;
            height: 1em;
            width: 1.18em;
            float: left;
            margin: 0 .25rem 0 0;
            color: $deselect;
        }

        button {
            margin-right: auto;
            display: block;
            padding: 22.5px 0;
            width: 100%;
            float: left;
            text-align: left;
            color: $deselect;
        }
        button:hover {
            background-color: $selectsecondary;
            color: $selectprimary;

            .viewIconContainer {
                color: $selectprimary;
            }
        }

        .selected {
            margin-left: 0px !important;
            color: $selectprimary;
            border-left: solid 5px $selectprimary;
            background-color: $selectsecondary;
            font-weight: 500;

            i.icon {
                padding-left: 25px;
            }

            .viewIconContainer {
                color: $selectprimary;
                padding-left: 25px;
            }
        }

    }

    .divider {
        margin: 30px 25px 30px;
        height: 0.5px;
        background-color: #ccc;
    }

    .logo {
        display: block;
        margin: auto;
        margin-bottom: 100px;
    }
}

@media only screen and (max-width: $mobile-breakpoint) {
    .ProfessorSidebar {
        top: 105px;
    }
}
