.commentPreview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.expandPreview {
  width: 2%;
  margin: 1%;
}

.userComment {
  position: relative;
  width: 98%;
  background-color: white;
  border-radius: 10px;
  display: flex column;
  align-items: flex-start;
  float: right;
  margin-bottom: 15px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.1));
  &.yourComment {
    background-color: #d6eafe;
    z-index: 100;
  }
  &.Preview {
    width: 96%;
    background-color: white;
    &.newComment {
      background-color: #f2e4cf;
    }
  }
  &:last-child {
    margin-bottom: 0px;
  }
  .commentInnerWrapper {
    margin: 10px 30px 0px 30px;
    .commentHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.Preview {
        margin-left: -20px;
        margin-bottom: 10px;
      }
      .commentNameAndProfile {
        display: flex;
        padding: 0;
        align-items: center;
        margin-top: 10px;
        &.Preview {
          margin-top: 0px;
          width: auto;
          min-width: max-content;
        }
        .commenterProfile {
          width: 25px;
          height: 25px;
          border-radius: 50%;
        }
        .commenterName {
          margin: 0px 0px 0px 5px;
          font-weight: 400;
          font-size: 13px;
        }
      }
      .previewCommentBody {
        color: #595959;
        text-align: left;
        font-size: 13px;
        font-weight: 300;
        padding-left: 20px;
        padding-right: 45px;
        flex-grow: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      .commentTime {
        color: #6f6f6f;
        font-weight: 300;
        font-size: 12px;
        &.Preview {
          width: auto;
          min-width: max-content;
        }
      }
    }
    .commentBody {
      margin-top: 18px;
      text-align: left;
      font-size: 14px;
      font-weight: 400;
      &.notUserComment {
        margin-bottom: 33px;
      }
    }
    .newCommentInput {
      border: none;
      border-radius: 4px;
      background: #ffffff;
      font-size: 14px;
      padding: 10px 10px;
      font-weight: 300;
      width: 97%;
      display: block;
      margin-top: 10px;
    }
    .commentButtons {
      margin-bottom: 15px;
      float: right;
      .notEditableButtonsWrapper {
        display: flex;
      }
      .commentButton {
        color: #5599db;
        font-weight: 400;
        text-decoration: underline;
        font-size: 12px;
        border: none;
        background-color: transparent;
        cursor: pointer;
      }
      .showDeleteMenu {
        display: flex;
        align-items: center;
        position: relative;
        .deleteDots {
          cursor: pointer;
        }
        .commentDelete {
          position: absolute;
          left: 0;
          top: 20px;
          background-color: white;
          padding: 6px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
          z-index: 100;
          &.myDelete {
            background-color: #d6eafe;
          }
        }
      }
    }
  }
}
