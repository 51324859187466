.QuestionView {
  color: $text-grey;
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  border-radius: 8px;

  .AddQuestion {
    position: relative;
    background-color: white; 
    border-radius: 8px;
    filter: drop-shadow(-2px 2px 8px rgba(0, 0, 0, 0.08));
    width: 100%;
    top: 30px;
    bottom: 30px;
    padding-bottom: 5px;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 40px;
    

    hr {
      display: block;
      height: 1px;
      border: 0;
      border-top: 0.65px solid #E2E2E2;
      margin: 0;
      padding: 0;
      width: 100%;
      
    }

    .queueHeader {
      position: relative;
      background-color: white;
      padding: 15px 15px 10px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      span {
        display: inline-block;
        font-size: 21px;
        vertical-align: middle;
      }

      .title {
        position: relative;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        color: #484848;
      }

      .xbutton {
        float: left;
        cursor: pointer;
      }
    }

    .tagsContainer {
      background-color: white;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      hr {
        display: block;
        height: 1px;
        border: 0;
        border-top: 0.65px solid #E2E2E2;
        margin: 0.5em 0;
        padding: 0;
        clear:both;
      }

      .tagsMiniContainer {
        padding: 10px 32px;
        clear: both;
        width: 100%;
        display:inline-block;
        text-align: left;
        @media only screen and (max-width: $mobile-breakpoint) {
          padding: 10px;
        }

        p {
          text-align: left;
          margin-bottom:0.5em;
        }

        .header {
          height: 17px;
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 14.625px;
          line-height: 17px;
          color: #696969;
          margin-bottom: 14px;

            .characterCount {
              color: #717171;

              &.warn {
                color: #ce2a2a;
              }
            }
        }

        .placeHolder {
          font-family: Roboto;
          font-style: normal;
          font-weight: 300;
          font-size: 12.675px;
          color: #BBBBBB;
        }

        .placeHolder.text {
            box-sizing: border-box;
            border-radius: 3.25px;
        }

        .TextInput {
            font-weight: normal;
            border: 0px;
            color: $text-grey;
            font-size:15px;
            padding: 8px;
            border-radius: 3.3px;
            resize: none;
            margin-top: 3px;
            border: 0.65px solid #979797;
            box-sizing: border-box;
            border-radius: 3.25px;
            height: 78px;
            outline: none;

            @media only screen and (min-width: $mobile-breakpoint) {
              border: 0.65px solid #979797;
            }

            &::placeholder {
              font-family: Roboto;
              font-style: normal;
              font-weight: 300;
              font-size: 12.675px;
              color: #BBBBBB;
            }
        }
        .TextInput:disabled {
          background-color: transparent;
        }


        .TextInput.location {
            width: 100%;
            height: auto;
            @media only screen and (min-width: $mobile-breakpoint) {
              width: 60%;
              height: auto;
            }
        }

        .TextInput.question {
            width: 100%;
        }
      }
    }

    .locationInput {
        position: relative;
        display: flex;
        flex-direction: column;
        .hybridCheckbox {
          margin-bottom: 14px;
        }
        .map.marker.alternate.icon {
            display: none;
            @media only screen and (min-width: $mobile-breakpoint) {
                display: block;
                // position: absolute;
                // top: 11px;
                // left: 4px;
                font-size: 20px;
                color: #e6e6e6;
            }
        }
    }

    .addButtonWrapper{
      padding-bottom: 20px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      display: flex;
      justify-content: center;
      
    }

    .AddButton {
        padding-bottom: 20px;
        width: 200.61px;
        height: 29.25px;
        color: white;
        font-size: 13px;
        border-radius: 3.25px;
        display: block;
        padding-top: 6px;
        cursor: pointer;
        background-color: #E2E2E2;
        transition: all 0.3s ease;
    }

    .AddButton.active {
        background-color: #5599DB;
    }
  }
}

.secondaryTags.false {
  .header {
    display: inline;
    padding-right: 25px;
  }
  .placeHolder {
    display: inline;
  }
}
