.ProfessorSettings {
    .title {
        font-weight: 500;
        font-size: 20px;
        padding: 30px 50px 0px;
    }

    .icon, .ui.inline.loader {
        margin-left: 10px;
    }

    .settingDesc {
        margin: 25px 30px;
    }

    .timeLimitSetting {
        .timeLimitSelector {
            margin: 25px 30px;
        }
    }

    .warningLabel {
        color: red;
    }

    .ui.selection.dropdown {
        margin: 0px 10px;
        height: 35px;
        width: 70px;
        background-color: #edeff2;
        border-radius: 0;
    }

    Input.charLimit {
        height: 35px;
        width: 35px;
        text-align: center;
        padding: 0;
    }

    Button.decrement,Button.increment {
        padding: 9px 1px;
        background-color: #edeff2;
        margin: 0px;
    }

    Button:hover {
        color: gray;
    }
} 