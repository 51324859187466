.react-datepicker {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.4);
    border: 0;
}

.react-datepicker__header {
    // background-color: white;
    border-bottom: 0;
}

.react-datepicker__time-list-item {
    border-bottom: solid 1px #e6e6e6;
    transition: all .15s $curve-standard;
}

.react-datepicker__day--keyboard-selected {
    background-color: $brand-primary;
    transition: all .15s $curve-standard;
}
.react-datepicker__day--keyboard-selected:hover {
    background-color: #4683bb;
}
