.SessionAlertModal {
    // background-color: white;
    // height: 100%;
    // color: @text-grey;
    // position: relative;

    .modalShadeAlert {
        display: none;
    }

    .modalShadeAlert.shade {
        display: block;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        background-color: rgba(112, 112, 112, 0.8);
    }

    .modalContent {
        .red {
            color: #c55a5a;
        }

        .yellow {
            color: #dc9c35;
        }
    }

    .modalContent.shade {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // width: 50%;
        z-index: 3;
        border-radius: 10px;
        overflow: hidden;
        background-color: white;
        width: 80%;
        max-width: 450px;
    }

    .text {
        margin: auto;
        width: 80%;
        padding: 30px 10px;
        font-size: 17px;

        .title {
            font-size: 30px;
            font-weight: 500;
            margin: 0;
        }

        .Icon {
            font-size: 30px;
            padding: 35px 0 20px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-content: stretch;
        text-align: center;
        outline: none;

        button {
            border: none;
            width: 100%;
            padding: 10px 0;
            font-size: 20px;
            background-color: #eaeaea;
        }

        .last {
            background: #7ab7fe;
            color: white;
        }
    }

    // Copied over from ProfessorOHInfoDelete
    .question {
        padding-top: 28px;
    }

    .info {
        background-color: #edeff2;
        margin: 15px 30px;
        margin-top: 0;
        padding: 15px;

        .ta {
            font-weight: bold;
            padding-bottom: 15px;
        }

        span {
            padding: 2px 20px;
            display: inline-block;
        }
    }
}
