.blogPostsWrapper {
  padding: 2em;
  .blogPost {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem;
    box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
    &__title {
      &-editable {
        margin: .5em;
        padding: 1em;
        border: 1px solid rgb(216, 216, 216);
        border-radius: 10px;
        box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);
        &:hover {
          box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
        }
        &:focus {
          outline: none;
        }
      }
    }
    &__description {
      &-editable {
        width: 100%;
        margin: .5em;
        padding: 1em;
        border: 1px solid rgb(216, 216, 216);
        border-radius: 10px;
        box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);
        &:hover {
          box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
        }
        &:focus {
          outline: none;
        }
      }
    }
    .blogPost__changeList {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .changeList{
      &__list {
        width: 100%;
      }
      &__add-listItem {
        border-radius: 4px;
        border: none;
        background-color: white;
        border: 1px solid black;
        padding: .25em;
        cursor: pointer;
        &:hover {
          box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
        }
        &:focus {
          outline: none;
        }
      }

      &__item {
        position: relative;
        border: none;
        width: 100%;
        textarea {
          width: 100%;
          margin: .5em;
          padding: .5em;
          padding-right: 1.5em;
          border: 1px solid rgb(216, 216, 216);
          border-radius: 10px;
          box-shadow: 0 2px 5px 1px rgba(64,60,67,.16);
          &:hover {
            box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
          }
          &:focus {
            outline: none;
          }
        }
      }

      &__delete {
        position: absolute;
        right: 0px;
        top:45%;
        transform: translateY(-50%);
        cursor: pointer;
        font-size: 1.3em;
        transition: font-size .2s ease;
        &:hover {
          font-size: 1.5em;
        }
      }
    }
    .dropdown__top {
      position: absolute;
      top: 1.5em;
      right: 0;
      &::before {
        content: '\2807';
        font-size: 3rem;
      }
      &:hover+.dropdown__menu {
          visibility: visible;
          max-height: 8rem;
          opacity: 1;
      }
    }
    .dropdown__hidden {
      visibility: hidden!important;
      max-height: 0;
    }
    .dropdown__menu {
      visibility: hidden;
      opacity: 0;
      max-height: 0;
      transition: all .2s ease-out;
      border: 1px solid gray;
      position: absolute;
      right: 0;
      &:hover {
        visibility: visible;
        max-height: 8rem;
        opacity: 1;
      }
      .dropdown__item {
        padding: .25em;
        margin: 0;
        cursor: pointer;
        &:hover {
          background-color: rgb(230,230,240);
        }
      }
    }
    &__list {
      list-style-type: circle;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 2rem;
      padding-right: 2rem;
      li {
        border: none;
      }
    }
    &__edit-controls {
      width: 70%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
    &__save, &__cancel {
      border-radius: 4px;
        border: none;
        background-color: white;
        border: 1px solid black;
        padding: .25em;
        cursor: pointer;
        &:hover {
          box-shadow: 0 1px 6px rgba(32, 33, 36, .28);
        }
        &:focus {
          outline: none;
        }
    }
    &__authorDate {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;
      font-weight: 500;
      font-style: italic;
      .blogPost__author {
        margin: .75rem;
        padding: 0;
      }
      .blogPost__date {
        margin: .75rem;
        padding: 0;
      }
      .blogPost__separator {
        padding: 0;
        margin: 0;
      }
    }
    &__dateEdited {
      position: absolute;
      bottom: 5px;
      right: 5px;
      font-style: italic;
      font-size: 9px;
      margin: 0;
      padding: 0;
    }
  }
}